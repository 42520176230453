<template>
    <Button variant="primary">
        <i :class="iconClass"></i>
        <span>{{ buttonText }}</span>
    </Button>
</template>
<script setup lang="ts">
const { t } = useI18n();
const props = defineProps<{ hasNotification?: boolean }>();

const iconClass = computed(() => "fas " + (props.hasNotification ? "fa-eye" : "fa-bell"));
const buttonText = computed(() =>
    props.hasNotification ? t("Notifications.Watching") : t("Notifications.CreateAlerts")
);
</script>
