<template>
    <th class="!p-0 h-9">
        <div class="flex relative items-center h-full">
            <span class="grow pl-5 pr-1">{{ $t("Global.Stock") }}</span>
            <button
                v-if="props.dialog"
                class="w-10 bg-gray-500 h-full"
                :class="{ active: hasNotification }"
                :title
                @click="click">
                <i class="fas fa-plus-sign" />
                <i class="fas fa-bell" />
            </button>
        </div>
    </th>
</template>
<script setup lang="ts">
const { t } = useI18n();
const { gtag } = useGtag();

const props = defineProps<{
    notification?: PartNotification;
    part?: CommonPart;
    dialog?: NotificationDialog;
}>();

const hasNotification = computed(() => {
    return (
        props.notification && (props.notification.PriceAlerts.length > 0 || props.notification.StockAlerts.length > 0)
    );
});

const title = computed(() => {
    return hasNotification.value ? t("Notifications.Watching") : t("Notifications.CreateProductNotification");
});

function click() {
    if (props.part && props.dialog) {
        gtag("event", "alerts_open_modal_from_stock_header", { part_number: props.part.PartNumber });
        props.dialog.openDialog(props.part, props.notification);
    }
}
</script>
