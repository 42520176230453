<template>
    <Dialog v-model:open="isOpen">
        <DialogContent id="login-modal">
            <template v-if="step == 'choose'">
                <h3 class="text-primary uppercase">{{ $t("BOMTool.AddToBOM") }}</h3>
                <h2 class="text-3xl font-medium">{{ $t("SignIn.WouldYouLikeToLogin") }}</h2>
                <p class="text-xl font-light text-muted mb-8">
                    {{ $t("SignIn.LoginForAdvancedFeatures") }}
                </p>
                <div class="flex justify-center gap-4">
                    <Button @click="continueAsGuest">
                        {{ $t("SignIn.ContinueAsGuest") }}
                    </Button>
                    <Button variant="primary" @click="startLogin">
                        {{ $t("SignIn.LoginButtonText") }}
                    </Button>
                </div>
            </template>
            <template v-else-if="step == 'login'">
                <h3 class="text-primary uppercase">{{ $t("SignIn.LoginButtonText") }}</h3>
                <br />
                <LoginForm @success="continueLogin" />
            </template>
        </DialogContent>
    </Dialog>
</template>
<script setup lang="ts">
const { gtag } = useGtag();
const guestCookie = useGuestCookie();
const emit = defineEmits<{ continue: [isAuthenticated: boolean] }>();

const isOpen = ref(false);
const step = ref<"choose" | "login">("choose");

function continueAsGuest() {
    guestCookie.value = true;
    emit("continue", false);
    gtag("event", "sign_in_modal_continue_as_guest");
    isOpen.value = false;
}

function startLogin() {
    gtag("event", "sign_in_modal_sign_in");
    step.value = "login";
}

async function continueLogin() {
    emit("continue", true);
    isOpen.value = false;
}

function openDialog() {
    step.value = "choose";
    isOpen.value = true;
    gtag("event", "sign_in_modal_open");
}

defineExpose({ openDialog });
</script>
