<template>
    <div v-if="globalConfig.Features.PART_MODELS_PAGE" ref="target" :class="containerClass" class="relative">
        <template v-if="hasModels">
            <Button as-child variant="link">
                <NuxtLink :href="props.href" @click="logPartModelClick()">
                    <i class="fas fa-microchip fa-xl"></i>
                    <span>{{ $t("PartModel.EDACADModels") }}</span>
                </NuxtLink>
            </Button>
            <Badge variant="highlight" class="p-0.5 absolute -top-1 right-4 text-xs uppercase font-bold leading-3">
                {{ $t("Search.New") }}
            </Badge>
        </template>
    </div>
</template>
<script setup lang="ts">
import { until, useElementVisibility } from "@vueuse/core";

const { gtag } = useGtag();
const globalConfig = useStateGlobalConfig();
const props = defineProps<{ partNumber: string; manufacturer: string; href: string; hasModels?: boolean }>();
const modelExists = ref(false);
const target = ref(null);
const targetIsVisible = useElementVisibility(target);
const api = useApi();

const hasModels = computed(() => {
    return props.hasModels || modelExists.value;
});

const containerClass = computed(() => ({ invisible: !hasModels.value }));

until(targetIsVisible).toBe(true).then(loadModelExists);

async function loadModelExists() {
    if (props.hasModels) return;

    modelExists.value = await api<boolean>("api/part/model-exists", {
        method: "POST",
        body: { PartNumber: props.partNumber, MfrVirtualPath: props.manufacturer },
    });
}

function logPartModelClick() {
    gtag("event", "part_models_click", { part_number: props.partNumber, manufacturer_name: props.manufacturer });
}
</script>
