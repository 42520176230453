<template>
    <div class="inline-block" data-testid="part-add-to-bom">
        <!-- Authenticated User -->
        <template v-if="isAuthenticated">
            <div class="dropdown" :class="{ open: active }">
                <!-- If the style of this button changes here, keep them in sync with the empty bot buttons on PartDetail\Index.cshtml -->
                <Button v-show="!active" variant="primary" :size="props.size" @click.stop="init">
                    <i class="fas fa-plus" />
                    <span>{{ $t("BOMTool.AddToBOM") }}</span>
                </Button>

                <!-- Active State -->
                <template v-if="active">
                    <!-- Input -->
                    <div
                        v-show="!success"
                        class="input-group input-group-with-button"
                        :class="{ 'has-error': inputHasError }">
                        <input
                            v-model="newBOMName"
                            type="text"
                            class="text-sm"
                            :placeholder="createInputPlaceHolder"
                            @click.stop
                            @focus="createInputFocus = true"
                            @blur="createInputFocus = false" />
                        <button
                            class="input-group-after btn btn-bg-primary"
                            type="button"
                            :disabled="busy"
                            @click.stop="addNewBOM">
                            <i class="fas fa-plus" />
                        </button>
                    </div>

                    <!-- Loading -->
                    <div v-if="busy" class="dropdown-menu p-1 text-xs w-full">
                        <span>{{ $t("BOMTool.Loading") }}</span>
                        <span class="inline-block fass fa-arrows-rotate animate-spin ml-2" />
                    </div>

                    <!-- Error -->
                    <div v-else-if="error" class="dropdown-menu p-1 text-xs text-danger">
                        <span v-html="error" />
                    </div>

                    <!-- User Selection -->
                    <ul
                        v-else-if="!success"
                        class="dropdown-menu text-xs md:right-0 divide-y min-w-48 max-h-48 md:max-h-[500px] overflow-y-auto">
                        <li
                            v-for="bom in sortedBOMs"
                            :key="bom.HashId"
                            class="block p-1 link-gray hover:bg-gray-50 truncate cursor-pointer">
                            <div v-if="bom.PartExists" class="flex justify-between p-1 gap-1">
                                <span>
                                    {{ bom.Name }} <i class="text-muted">{{ $t("BOMTool.Added") }}</i>
                                </span>
                                <a :href="'/bom/' + bom.HashId" @click.stop>
                                    {{ $t("Global.View") }}
                                </a>
                            </div>
                            <div v-else class="flex justify-between p-1" @click.stop="addToBOM(bom)">
                                <span>{{ bom.Name }}</span>
                            </div>
                        </li>
                    </ul>

                    <!-- Success -->
                    <Button v-if="success" variant="success" :size="props.size">
                        <i class="fass fa-check" />
                        <span>{{ $t("BOMTool.Success") }}</span>
                    </Button>
                </template>
            </div>
        </template>

        <!-- Anonymous User -->
        <template v-else>
            <!-- Success -->
            <Button v-if="success" variant="success" :size="props.size">
                <i class="fass fa-check" />
                <span>{{ $t("BOMTool.Success") }}</span>
            </Button>

            <!-- Error -->
            <Button v-else-if="error" variant="warning" :size="props.size">
                {{ error }}
            </Button>

            <!-- Busy -->
            <Button v-else-if="busy" variant="primary" :size="props.size" disabled>
                <i class="inline-block fass fa-arrows-rotate animate-spin" />
                <span>{{ $t("BOMTool.Loading") }}</span>
            </Button>

            <!-- Adding -->
            <Button
                v-else-if="!busy && selectedBOM && !selectedBOM.PartExists"
                variant="primary"
                :size="props.size"
                @click="addToBOM(selectedBOM)">
                <i class="fas fa-plus" />
                <span>{{ $t("BOMTool.AddToBOM") }}</span>
            </Button>

            <!-- Part Exists -->
            <Button v-else as="a" variant="primary" href="/bom/local" :size="props.size">
                <span>{{ $t("BOMTool.ViewBOM") }}</span>
                <i class="fass fa-chevron-right" />
            </Button>
        </template>
        <LoginDialog ref="loginDialog" @continue="onLoginContinue" />
    </div>
</template>

<script setup lang="ts">
import { AxiosError } from "axios";
import type { ButtonVariants } from "../ui";

const { gtag } = useGtag();
const { t } = useI18n();

const isAuthenticated = useStateIsAuthenticated();
const { toast } = useToast();
const guestCookie = useGuestCookie();
const loginDialog = useTemplateRef("loginDialog");

const props = withDefaults(defineProps<{ part: Partial<PartRecord>; size?: ButtonVariants["size"] }>(), { size: "md" });

// TODO: Consolidate Bom data types somewhere else. This type only
// defines the properties being used in this component.
type BomListItem = {
    HashId: string;
    Name: string;
    PartCount: number;
    PartExists: boolean;
    Parts: BomPart[];
};

type BomPart = {
    Id: number;
    ManufacturerId: number;
    RootManufacturerId: number;
    PartNumberScrubbedNonMeaningful: string;
};

const boms = ref<BomListItem[]>([]);
const selectedBOM = ref<BomListItem | null>(null);
const active = ref(false);
const busy = ref(false);
const error = ref("");
const inputHasError = ref(false);
const success = ref(false);
const defaultBOM = ref(null);
const newBOMName = ref("");
const createInputFocus = ref(false);

const sortedBOMs = computed(() => {
    if (!boms.value) {
        return [];
    }

    return boms.value.slice().sort((a, b) => {
        if (a.Name > b.Name) return 1;
        if (a.Name < b.Name) return -1;
        return 0;
    });
});

const createInputPlaceHolder = computed(() => {
    if (createInputFocus.value) {
        return t("BOMTool.BOMTitlePlaceHolder");
    } else {
        return t("BOMTool.BOMUploadTitle");
    }
});

onMounted(() => {
    if (!isAuthenticated.value) {
        setupAnonymousBom();
    }

    document.addEventListener("click", close);
});

onBeforeUnmount(() => {
    document.removeEventListener("click", close);
});

async function getBOMs() {
    if (isAuthenticated.value) {
        busy.value = true;
        boms.value = [];

        try {
            const response = await axios.get("/api/bom", {
                params: {
                    manufacturerId: props.part.Manufacturer?.Id,
                    partNumber: props.part.PartNumberScrubbedNonMeaningful,
                    requireEdit: true,
                },
            });
            boms.value = response.data;
        } catch (ex) {
            error.value = t("Global.GenericError");
            console.error(ex);
        }

        busy.value = false;
    }
}

async function addNewBOM() {
    if (!newBOMName.value.trim()) {
        inputHasError.value = true;
        return;
    }

    inputHasError.value = false;

    if (!defaultBOM.value) {
        await getDefaultBOM();
    }

    createBOM();
}

async function createBOM() {
    const bom = JSON.parse(JSON.stringify(defaultBOM.value));
    bom.Name = newBOMName.value;
    bom.Parts = [
        {
            PartId: props.part.Id,
            PartNumber: props.part.PartNumber,
            PartNumberScrubbedNonMeaningful: props.part.PartNumberScrubbedNonMeaningful,
            Quantity: 1,
            SortNum: 0,
        },
    ];

    busy.value = true;

    try {
        const response = await axios.post("/api/bom", bom);
        success.value = true;
        selectedBOM.value = response.data;
        logAddToBOM();
        complete();
    } catch (ex) {
        error.value = t("Global.GenericError");
        console.error(ex);
    }

    busy.value = false;
}

function onLoginContinue(isAuthenticated: boolean) {
    if (isAuthenticated) {
        toast({ description: t("BOMTool.PartAddToBomSignInMessage"), variant: "success" });
    } else {
        addToBOM(selectedBOM.value, true);
    }
}

async function addToBOM(bom: BomListItem | null, skipModal = false) {
    if (!bom || bom.PartExists) {
        return;
    }

    if (!isAuthenticated.value) {
        if (bom.Parts.length >= BOM_TOOL.MAX_PARTS) {
            toast({ description: t("BOMTool.AddTooManyPartsWarning"), variant: "warning" });
            return;
        }

        if (!guestCookie.value && !skipModal) {
            loginDialog.value?.openDialog();
            return;
        }
    }

    selectedBOM.value = bom;

    const bomSearchPart = {
        PartId: props.part.Id,
        PartNumber: props.part.PartNumber,
        PartNumberScrubbedNonMeaningful: props.part.PartNumberScrubbedNonMeaningful,
        Quantity: 1,
        SortNum: isAuthenticated.value ? bom.PartCount : bom.Parts.length,
    };

    busy.value = true;

    try {
        const response = await axios.post<BomPart>(`/api/bom/${bom.HashId}/part`, bomSearchPart);
        success.value = true;

        if (!isAuthenticated.value) {
            if (!selectedBOM.value.Parts) {
                selectedBOM.value.Parts = [];
            }

            // set anon "rowId" Id for key
            response.data.Id = BomHelpers.getAnonBomNextPartId(selectedBOM.value);

            selectedBOM.value.Parts.push(response.data);
            selectedBOM.value.PartExists = true;
            selectedBOM.value.PartCount++;
            saveAnonBOM();
        }

        logAddToBOM();
        complete();
    } catch (ex) {
        if (ex instanceof AxiosError && ex.response?.data?.Message) {
            error.value = ex.response?.data?.Message;
        } else {
            error.value = t("Global.GenericError");
        }
    }

    busy.value = false;

    if (!isAuthenticated.value) {
        gtag("event", "bom_tool_open_add_to_bom", { user_type: "Anonymous" });
    }
}

function saveAnonBOM() {
    const bom = JSON.parse(JSON.stringify(selectedBOM.value));
    delete bom.PartExists;
    BomHelpers.setAnonBOM(bom);
}

function init() {
    busy.value = false;
    success.value = false;
    error.value = "";
    newBOMName.value = "";
    active.value = true;

    gtag("event", "bom_tool_open_add_to_bom", { user_type: "Authenticated" });

    getBOMs();
}

async function getDefaultBOM() {
    busy.value = true;

    try {
        const response = await axios.get("/api/bom/default");
        defaultBOM.value = response.data;
    } catch (ex) {
        error.value = t("Global.GenericError");
        console.error(ex);
    }

    busy.value = false;

    return defaultBOM.value;
}

function logAddToBOM() {
    gtag("event", "bom_tool_add_to_bom", {
        manufacturer_name: props.part.Manufacturer,
        part_number: props.part.PartNumberScrubbedNonMeaningful || props.part.PartNumber,
    });
}

function close() {
    success.value = false;
    active.value = false;
}

function complete() {
    success.value = true;
    // reset after 3 seconds
    setTimeout(close, 3000);
}

async function setupAnonymousBom() {
    const bom = BomHelpers.getAnonBOM();
    if (bom) {
        selectedBOM.value = bom;
    } else {
        selectedBOM.value = await getDefaultBOM();
        BomHelpers.setAnonBOM(selectedBOM.value);

        // no need to set `PartExists` since this is a brand new bom.
        return;
    }

    if (!selectedBOM.value) {
        return;
    }
    selectedBOM.value.PartExists = selectedBOM.value.Parts.some((x) => {
        // since we may temporarily have some anon boms that don't have the RootManufacturerId property set in local storage, we need this check
        if (x.hasOwnProperty("RootManufacturerId")) {
            return (
                x.RootManufacturerId == props.part.RootManufacturerId &&
                x.PartNumberScrubbedNonMeaningful == props.part.PartNumberScrubbedNonMeaningful
            );
        } else {
            return (
                x.ManufacturerId == props.part.Manufacturer?.Id &&
                x.PartNumberScrubbedNonMeaningful == props.part.PartNumberScrubbedNonMeaningful
            );
        }
    });
}
</script>
